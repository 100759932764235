import React from 'react'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import { useState, useEffect } from 'react'
import { getUserAccountInfo } from '../../helpers/APIHelper'
import { Table } from 'react-bootstrap'
import { getSchoolSmallLogo, getClassImage } from '../../helpers/RANHelper'
import { Badge } from 'react-bootstrap'
import $ from 'jquery'
import Bubbles from '../loading/Bubbles';

const Account = ( {setAuthentication}) => {
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState({
        data : {}
    })

    useEffect(() => {
        async function fetchData() {
             await fetchInitialData();
        }
        fetchData();
    }, []);

    const setUserBasicInfo = (premiumPoints, gamePoints) => {
        $('#premiumPoints').html(premiumPoints) 
        $('#gamePoints').html(gamePoints) 
    } 

    const fetchInitialData = async () => {
        var result = await getUserAccountInfo(setAuthentication);
        if(result !== null) {
            if(result.status === 200) {
                setData(result.data)
                setUserBasicInfo(result.data.premiumPoints, result.data.gamePoints)
                setLoading(false)
            }
        }
    }

    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">ACCOUNT INFO</Card.Header>
            <Card.Body>
                {
                    isLoading ? 
                    <Bubbles/>
                    : 
                    (<> 
                        <Table bordered variant="dark">
                            <tbody className="text-left">
                                <tr>
                                    <td>Username</td>
                                    <td><b>{data.username}</b></td>
                                </tr>
                                <tr>
                                    <td>Email address</td>
                                    <td><b>{data.email}</b></td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td><b>{data.status}</b></td>
                                </tr>
                                <tr>
                                    <td>Last Login Date</td>
                                    <td><b>{data.lastLoginDate}</b></td>
                                </tr>
                                <tr>
                                    <td>Last Used IP address</td>
                                    <td><b>{data.lastUsedIPAddress}</b></td>
                                </tr>
                                <tr>
                                    <td>Referral Points</td>
                                    <td><b>{data.referralPoints}</b></td>
                                </tr>
                            </tbody>
                        </Table>
                        <div className="mt-4">
                            <h4>Characters on RAN Online Echo EP3</h4>
                            <ListGroup>
                            { data.characters.map((data, index) => 
                                <ListGroup.Item key={index} className="rankings-item">
                                    <div className="d-flex">
                                        <img src={getClassImage(data.classId)} className="rounded" alt={data.class} />
                                    </div>
                                    <div className="rankings-item-body">
                                        <div className="name"><img src={getSchoolSmallLogo(data.school)} alt="School" />&nbsp; {data.name} </div>
                                        <div className="info">
                                            <span>Level: <b>{data.level}</b></span> | &nbsp;
                                            <span>Gold: <span style={{color: "#ffc107", fontWeight: "bold"}}><b>{data.gold}</b></span></span> | &nbsp;
                                            {
                                                data.status === "Deleted" &&
                                                <span>Status: <span style={{color: "#dc3545", fontWeight: "bold"}}><b>{data.status}</b></span></span>
                                            }
                                            {
                                                data.status === "Running" &&
                                                <span>Status: <span style={{color: "green", fontWeight: "bold"}}><b>{data.status}</b></span></span>
                                            }
                                        </div>
                                    </div>
                                <div className="text-white">
                                        <Badge bg="danger" text="white">{index + 1}</Badge>
                                </div>
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                        </div>
                    </>)
                }
            </Card.Body>
        </Card>
    )
}

export default Account
