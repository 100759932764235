import React from 'react'
import Card from 'react-bootstrap/Card'
import { useState } from 'react'

const ServerInfo = () => {

    const [data] = useState({
        data : '<div class="panel-body"><p></p><h4>Available Servers:</h4>🔲 Strife Server <br><h4>Classic Gameplay!</h4>🔲4 Class (Brawler, Swordsman, Archer and Shaman) <br>🔲150 Max Level <br>🔲127 Max Skill <br>🔲High Exp Rate (After Cap Race) <br>🔲Low Drop Rate <br>🔲Click to Learn Skill <br>🔲Classic Gameplay <br><p></p></div>'
    })
    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">SERVER INFO</Card.Header>
            <Card.Body>
            <span>
                <div dangerouslySetInnerHTML={{
                     __html: data.data
                     }}>
                </div>
             </span>
            </Card.Body>
        </Card>
    )
}

export default ServerInfo
