import React from 'react'
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { useLocation, Link } from 'react-router-dom';
import '../css/NavMenu.css'
import { useState } from 'react';
import logo from '../assets/images/logo.png'
import { FaFacebook } from "react-icons/fa";

const NavMenu = ( props ) => {
    
    let location = useLocation();
    const [collapse, setCollapse] =  useState(true);
    
    const toggleNavBar = () => {
        setCollapse(!collapse);
    }

    return (
        <>
            <div className="top-nav">
                <div className="container">
                    <div className="top-nav-text">
                        RAN Online Echo EP3
                    </div>
                    <ul className="top-nav-social-ul">
                        <li>
                            <a target="_blank" href="https://www.facebook.com/RanOnlineEchoEP3/" rel="noreferrer">
                                <FaFacebook />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="jumbotron text-center" style={{paddingTop:"8px"}}>
                    <div className="container">
                        <img src={logo} alt="logo" width="250" />
                    </div>
            </div>
            <header>
                <Navbar className="container navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow navbar-container" light>
                        <Container>
                            <NavbarBrand tag={Link} className="text-white" to="/">RAN Online Echo EP3</NavbarBrand>
                            <NavbarToggler onClick={toggleNavBar} className="mr-2" />
                            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapse} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem className={`${location.pathname === '/' ? 'nav-item-active': ''}`}>
                                <NavLink tag={Link} className="text-white" to="/">Home</NavLink>
                                </NavItem>
                                <NavItem className={`${location.pathname === '/news' ? 'nav-item-active': ''}`}>
                                <NavLink tag={Link} className="text-white" to="/news">News</NavLink>
                                </NavItem>
                                <NavItem className={`${location.pathname === '/downloads' ? 'nav-item-active': ''}`}>
                                <NavLink tag={Link} className="text-white" to="/downloads">Downloads</NavLink>
                                </NavItem>
                                <NavItem className={`${location.pathname === '/server-info' ? 'nav-item-active': ''}`}>
                                <NavLink tag={Link} className="text-white" to="/server-info">Server Info</NavLink>
                                </NavItem>
                                {/* {
                                    props.isAuthenticated && <NavItem className={`${location.pathname === '/shop' ? 'nav-item-active': ''}`}>
                                    <NavLink tag={Link} className="text-white" to="/shop">Shop</NavLink>
                                    </NavItem>
                                } */}
                                <NavItem className={`${location.pathname === '/rankings' ? 'nav-item-active': ''}`}>
                                <NavLink tag={Link} className="text-white" to="/rankings">Rankings</NavLink>
                                </NavItem>
                                {/* <NavItem className={`${location.pathname === '/topup' ? 'nav-item-active': ''}`}>
                                <NavLink tag={Link} className="text-white" to="/topup">Top Up</NavLink>
                                </NavItem> */}
                                <NavItem>
                                <NavLink className="text-white" href="https://www.facebook.com/groups/RanOnlineEchoEP3" target="_blank">FB Group</NavLink>
                                </NavItem>
                            </ul>
                            </Collapse>
                        </Container>
                    </Navbar>
        </header>
        </>
    )
}

export default NavMenu;
